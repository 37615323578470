import React, { useState } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Seo from "../seo"
import checkboxImg from "/src/assets/img/checkbox.svg"
import letterImg from "/src/assets/img/from_anywhere_1.png"
import letterImg1 from "/src/assets/img/thousands_times.png"
import letterImg2 from "/src/assets/img/from_anywhere.png"
import letterImg3 from "/src/assets/img/print_services.png"
import arrow from "/src/assets/img/arrow.svg"
import friendly from "/src/assets/img/friendly.svg"
import technology from "/src/assets/img/technology.svg"
import comprehensive from "/src/assets/img/comprehensive.svg"
import subscriptions from "/src/assets/img/subscriptions.svg"
import industry from "/src/assets/img/industry.svg"
import support from "/src/assets/img/support.svg"
import Slider from "./slider"
import HowItWorksComponent from "../how-it-works/how-it-works.component"

const WhatWeDo = ({ steps, texts }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [direction, setDirection] = useState("next")
  const Steps = steps;
  const Texts = texts;
  



  const slides = [
    {
      title: "Mail Letters Online: From Anywhere in the Country",
      description: `With our intuitive platform and user-friendly interface, you can easily mail a letter online or integrate our application seamlessly with your existing systems through our print and mail API. Submit your letter requests by our stated cutoff time, and we will print and mail them by the following business day, ensuring timely induction into the USPS mail stream. <br />  Our service is ideal for all industries requiring USPS mail, including, but not limited to: <br /><br />
      <div style="display: flex, gap: 10px, flex-wrap: wrap">
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF; background: #fff;">Law Firms</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Property Management Companies</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Association Management Companies</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Self-Storage Companies</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Financial Institutions</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Insurance Companies</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Land Service Companies</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">State and Local Government</div>
      </div>`,
      img: letterImg,
    },
    {
      title:
        "Certified Mail Service: No More Labels or Trips to the Post Office",
      description: `Certified Mail is usually a time-consuming process involving manual forms, labels, post office visits, and tracking on the USPS website. Our service streamlines this: simply upload your document, and we handle the rest. <br /> <br />  While delivery depends on USPS, we ensure your letter is prepared and inducted, with all USPS scans available on our platform. You also get an image of the mailed letter and the Return Receipt Electronic (RRE) signature (if applicable), creating a fully digital experience. Send Certified Mail from home or the office in just a few clicks.`,
      img: letterImg2,
    },
    {
      title: "Comprehensive Letter Shop Services: With a Modern Take",
      description: `Letter shops have handled printing, personalization, mailing, and more since the 19th century, but many still use outdated methods. At UploadLetters.com, we prioritize modern technology.
Our application is user-friendly, secure, and integrates with USPS and proprietary print systems. It’s fully automated, reducing human errors and supporting agile workflows with pre-configured options. <br /> <br />  Unlike most letter shops that require large print runs due to system and equipment constraints, our infrastructure supports both short and long print cycles with various print and mailing requirements, including: <br /> <br /> 
      <div style="display: flex, gap: 10px, flex-wrap: wrap">
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF;">Certified Mail, Priority Mail, or First-Class Mail</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">#10 or 9x12 envelopes</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Affidavits of Mailing</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Priority Mail Envelopes, Priority Mail Boxes</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Color or Black & White Print</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Custom Inserts</div>
        <div style="padding: 5px 10px; border: 1px solid #1880FF; border-radius: 10px; display: inline-block; margin-bottom: 10px; color: #1880FF">Single-Sided Print or Double-Sided Print</div>
      </div>
      `,
      img: letterImg3,
    },
    {
      title: "Bulk and Mass Mailings: Send One Letter or Thousands at a Time",
      description: `We serve clients with varying mailing needs, from one-time large mass mailings to daily mailings of different sizes. For clients sending to multiple recipients, we offer the ability to extract addresses directly from their PDFs, allowing them to submit thousands of addresses for mailings in seconds. <br /> <br />  We also offer an Import from File option, where users can map an Excel or CSV file to the documents they want to send. Alternatively, users can leverage our print and mail API to send their mail directly from their existing platforms. We also offer competitive custom pricing for businesses sending large amounts of mail.`,
      img: letterImg1,
    },
  ]

  const handleNext = () => {
    setDirection("next")
    setActiveIndex(prevIndex => (prevIndex + 1) % slides.length)
  }

  const handlePrev = () => {
    setDirection("prev")
    setActiveIndex(prevIndex => (prevIndex - 1 + slides.length) % slides.length)
  }

  const handleIndicatorClick = index => {
    setActiveIndex(index)
  }

  return (
    <>
      {/* <Seo title={props.seoTitle} description={props.seoDescription} /> */}
      <div className="whatWeDoSlide" id="what-we-do">
          <div className="slideContent whatWeDoContBG">
            <div className="mainContainer whatWeDoCont ">
              <div className="whatWeDoTitleBlock">
                <div>
                  <h1 className="whatWeDoSubTitle">
                    UploadLetters.com: <br /> Your{" "}
                    <span className="whatWeDoSubTitleBlue">Premier</span>{" "}
                    <span className="whatWeDoSubTitleBlue">Partner</span> for
                    Online
                    Mailing Services
                  </h1>
                  {/* <div className="whatWeDoContainerButton">
                <a
                  href="/app.uploadletters.com/login"
                  className="button whatWeDoButton"
                >
                  Get Started <div className="whiteDot" />
                </a>
              </div> */}
                </div>
                <div className="whatWeDoDescription">
                  At UploadLetters.com, we offer comprehensive mailing solutions
                  tailored to meet the diverse needs of businesses across
                  various industries. Our services range from standard USPS
                  First-Class Mail fulfillment to more complex mailings
                  involving Certified Mail or Text Mail, accommodating unique
                  requirements such as large page counts, proof of mailing,
                  dynamic inserts, and more. Let us streamline your internal
                  mailing processes so you can focus on growing your business.
                </div>
              </div>
            </div>
          </div>
          <HowItWorksComponent steps={Steps} texts={Texts} />
        {/* <div>
          <div
            className={`carouselContainer slideContent ${
              direction === "next" ? "forwards" : "backwards"
            }`}
          >
            <TransitionGroup>
              <CSSTransition
                key={activeIndex}
                classNames="carouselSlide"
                timeout={500}
              >
                <div className="carouselSlide">
                  <div className="carouselSlideWrapper">
                    <img
                      src={slides[activeIndex].img}
                      alt={`img${activeIndex + 1}`}
                      className="carouselSlideWrapperImg"
                    />
                    <div className="carouselTitleContainer">
                      <h2 className="carouselTitle">
                        {slides[activeIndex].title}
                      </h2>
                      <p
                        className="carouselSlideWrapperDescr"
                        dangerouslySetInnerHTML={{
                          __html: slides[activeIndex].description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
            <div className="indicators">
              {slides.map((_, index) => (
                <button
                  key={index}
                  className={`indicator ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleIndicatorClick(index)}
                ></button>
              ))}
            </div>
          </div>
        </div>
        <div className="indicatorsMobile">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`indicator ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleIndicatorClick(index)}
            ></button>
          ))}
        </div> */}
        <Slider />
        <div className="slideContent" style={{paddingBottom: "0" }}>
          <div
            className="mainContainer whatWeDoCont"
            style={{ background: "#f2f3fc" }}
          >
            <h2 className="chooseTitle">Why Choose UploadLetters.com?</h2>
            <div className="whatIsContBlock">
              <div className="advantagesItem">
                <img src={friendly} className="advantagesCheckbox" />
                <div className="advantagesDescription">
                  <span className="whatWeDoTitle">
                    User-Friendly Interface with Comprehensive Tracking
                    Capabilities
                  </span>{" "}
                  <br />
                  Designed with ease of use in mind, our application is
                  intuitive and straightforward, offering complete tracking from
                  initial upload to delivery.
                </div>
              </div>
              <div className="advantagesItem">
                <img src={technology} className="advantagesCheckbox" />
                <div className="advantagesDescription">
                  <span className="whatWeDoTitle">
                    Technology-Driven Solutions
                  </span>{" "}
                  <br />
                  For clients looking to bypass our application altogether, our
                  platform integrates seamlessly with your existing systems,
                  reducing manual effort and ensuring accuracy.
                </div>
              </div>
            </div>

            <div className="whatIsContBlock">
              <div className="advantagesItem">
                <img src={subscriptions} className="advantagesCheckbox" />
                <div className="advantagesDescription">
                  <span className="whatWeDoTitle">
                    No Subscriptions or Hidden Fees
                  </span>{" "}
                  <br />
                  Pay only for the letters or notices you send, even if it's
                  just a few per year. We ensure transparent pricing with no
                  unexpected charges.
                </div>
              </div>
              <div className="advantagesItem">
                <img src={comprehensive} className="advantagesCheckbox" />
                <div className="advantagesDescription">
                  <span className="whatWeDoTitle">Comprehensive Services</span>{" "}
                  <br />
                  From First-Class Mail, Certified Mail and Priority Mail we
                  cover all your mailing needs.
                </div>
              </div>
            </div>

            <div className="whatIsContBlock">
              <div className="advantagesItem">
                <img src={industry} className="advantagesCheckbox" />
                <div className="advantagesDescription">
                  <span className="whatWeDoTitle">Industry Expertise</span>{" "}
                  <br />
                  Our team has decades of experience in document solutions and
                  technology, bringing you the best in mailing services.
                </div>
              </div>
              <div className="advantagesItem">
                <img src={support} className="advantagesCheckbox" />
                <div className="advantagesDescription">
                  <span className="whatWeDoTitle">Dedicated Support</span>{" "}
                  <br />
                  Our team is always ready to assist with any questions you may
                  have.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhatWeDo